<template>
<div>
  <div class="barra-impressao py-2 px-2">
    <b-button class="btn-green align-right" @click="printModal">
      {{$t('pdfs.imprimir')}}
    </b-button>
  </div>
      <b-card  id="modalConteudo">
        <b-card-header class="text-center">
          <img src="@/assets/img/pdf/retangulo-greenn.png" class="img-centralizada mb-2">
          <img src="@/assets/img/pdf/logo-greenn.png" class="img-centralizada">
        </b-card-header>
        <b-card-body v-if="sale">
            <span>
                {{ $t('pdfs.ola') }} {{ sale.client.name}}!
            </span>

            <br/>

            <span>
                {{ $t('pdfs.queremos_informar') }} 
                <b>{{ $t('pdfs.devolucao_transacao') }}</b> 
                {{ $t('pdfs.foi_efetivada') }} 
                <b>{{ $t('pdfs.sucesso') }}</b>.
            </span>
        <img src="@/assets/img/pdf/dados-estorno.png" class="img-baloes">

        <div class="transaction-details">
          <p>{{ $t('pdfs.id_transacao') }}:</p>
          <span>{{ sale.id }}</span>
        </div>

        <div class="transaction-details">
          <p>{{ $t('pdfs.data_transacao') }}:</p>
          <span>{{ moment(sale.created_at).format('DD/MM/YYYY') }}</span>
        </div>

        <div class="transaction-details">
          <p>{{ $t('pdfs.valor_transacao') }}:</p>
          <span>R$ {{ formatMoney(sale.total) }}</span>
        </div>

        <div class="transaction-details">
          <p>{{ $t('pdfs.data_estorno') }}:</p>
          <span>{{ moment(sale.refunded_at).format('DD/MM/YYYY') }}</span>
        </div>

        <div class="transaction-details">
          <p>{{ $t('pdfs.valor_extorno') }}:</p>
          <span>R$ {{ formatMoney(sale.total) }}</span>
        </div>

        <img src="@/assets/img/pdf/dados-titular.png" class="img-baloes">

        <div class="transaction-details" v-if="sale.client">
          <p>{{sale.client.document_type.toUpperCase()  }}:</p>
            <span>
              {{maskedDocument}}
            </span>
        </div>

        <div class="transaction-details" v-if="sale.refund_solicitations && sale.refund_solicitations.bank">
          <p>{{ $t('pdfs.agencia') }}:</p>
          <span>
            {{ sale.refund_solicitations.bank.name ? sale.refund_solicitations.bank.name : null }}
        </span>        
        </div>


        <span>{{ $t('pdfs.conte_conosco') }}</span>
        <br>
        <span>{{ $t('pdfs.ate_mais') }},<br>
            <b>{{ $t('pdfs.time_greenn') }}</b>
        </span>
</b-card-body>

<div class="text-center">

    <div class="banner-img">
        <img src="@/assets/img/pdf/banner-info-greenn.png" class="img-centralizada" style="margin-top: 10px; width: 100%">
    </div>

    <img src="@/assets/img/pdf/icone-greenn.png" class="img-centralizada" style="margin-top: 10px; width: 25px;">
    
    <div>{{$t('pdfs.do_seu_lado')}}</div>
    <div class="center-hearth">
        <div class="display-heart">
            <img style="width: 10px; height:auto" src="@/assets/img/pdf/green_heart.png">
        </div>
    </div>
    
</div>

</b-card>

</div>
</template>
  
<script>
import SaleService from "@/services/resources/SaleService";
import moment from "moment";
import Money from "@/mixins/money";
const serviceSale = SaleService.build();
export default {
  name: "BoletoPdf",

  mixins: [Money],

  data() {
    return {
      moment: moment,
      sale: {},
    };
  },
  computed: {
  maskedDocument() {
    let document = this.sale.client.document;
    let middlePart = document.slice(3, 12);
    return document.replace(middlePart, '*********');
  }
},
  
methods: {
  getSale(){
    let sale_id = this.$route.params.id;
    serviceSale.read('receipt-pdf?id=' + sale_id)
    .then(response => {
        this.sale = response.sale;
    })
    .catch(err => console.error(err))
  },

  printModal() {
    let printContents = document.getElementById('modalConteudo').innerHTML;  

    let printDiv = document.createElement('div');
    
    printDiv.innerHTML = printContents;
    
    document.body.appendChild(printDiv);
    
    document.body.removeChild(printDiv);

    window.print();
  }
},
mounted() {
  this.getSale();
}
};
</script>

<style >
.img-centralizada {
  display: flex;
  margin: auto;
  width: 20%;
}
.container {
  max-width: 600px;
  margin: 0 auto;
  padding: 5px;
}
.transaction-details {
  margin-bottom: 5px;
  padding-bottom: 5px;
  border-bottom: 1px solid #ccc;
}
.transaction-details p {
  margin-top: 0;
}
.transaction-details p {
  margin-bottom: 5px;
}

.img-baloes {
  padding: 25px 0 25px 25px;
  margin-left: -25px;
  width: 100%;
}
.modal-dialog {
  max-width: 1200px;
}
.center-hearth{
  display: flex; 
  justify-content: center;
   align-items: center;
    height: 100%;
}
.display-hearth{
  display: flex; 
  margin-top: auto;
}
.barra-impressao {
  background-color: lightgray;
  display: flex;
  justify-content: flex-end; 
}
@media print {
  .barra-impressao {
    display: none;
  }
}
</style>
  